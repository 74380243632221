.forgotPasswordCustomContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  position: relative;
}

.forgotPasswordCustomFormContainer {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  z-index: 1;
  position: relative;
}

.forgotPasswordCustomFormContainer form {
  display: flex;
  flex-direction: column;
}

.forgotPasswordCustomFormContainer input {
  border: 1px solid #1976d2;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 20px;
  outline: none;
  transition: 0.2s;
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
  position: relative;
}

.inputWrapper {
  position: relative;
  margin-bottom: 20px;
}

.forgotPasswordCustomFormContainer input:focus {
  border-color: #0d47a1;
  box-shadow: 0 0 0 1px #0d47a1;
}

.forgotPasswordCustomFormContainer label {
  position: absolute;
  left: 10px;
  top: 0;
  transform: translateY(-50%);
  background: #ffffff;
  padding: 0 5px;
  color: #1976d2;
  font-weight: 500;
  font-size: 14px;
}

.forgotPasswordCustomSubmitButton {
  background-color: #1976d2;
  border-radius: 4px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  transition: 0.2s;
  width: 100%;
}

.forgotPasswordCustomSubmitButton:hover {
  background-color: #0d47a1;
}

.forgotPasswordCustomAnimatedDots {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.forgotPasswordCustomAnimatedDots span {
  background-color: #1976d2;
  border-radius: 50%;
  margin: 0 5px;
  animation: bounce 1.5s infinite ease-in-out;
  opacity: 0.6;
  width: 20px;
  height: 20px;
}

.forgotPasswordCustomAnimatedDots span:nth-child(2) {
  animation-delay: -0.5s;
}

.forgotPasswordCustomAnimatedDots span:nth-child(3) {
  animation-delay: -0.25s;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-20%);
  }
}

.customButtonBack {
  background-color: #3498db;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  margin: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.customButtonBack:hover {
  background-color: #2980b9;
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
