:root {
  --light-gray: #eeeeee;
  --primary-blue: #0092ca;
  --dark-gray: #393e46;
  --white: #ffffff;
  --gray: #d4d4d4;
  --light-blue: #00b7fd;
}

.info-label {
  color: var(--dark-gray);
  font-weight: 600;
  padding-right: 1rem;
}

.info-value {
  color: var(--primary-blue);
  font-family: monospace;
}

.info-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.info-wrapper {
  margin-bottom: 1rem;
}

.process-wrapper {
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: 1rem;
}

.toggle-movements-button {
  background-color: var(--primary-blue);
  color: var(--white);
  border-radius: 4px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
}

.toggle-movements-button:hover {
  background-color: var(--light-blue);
}

.modal-button {
  font-size: 1em;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10px;
}

.modal-button:focus {
  outline: none;
}

.close-button {
  background-color: var(--primary-blue);
  color: var(--white);
  border-radius: 4px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.close-button:hover {
  background-color: var(--light-blue);
}

.close-button:focus {
  outline: none;
}

.movement {
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.movement:last-child {
  margin-bottom: 0;
}