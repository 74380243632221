.sort-toggle-container {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  color: #212121;
  font-weight: 500;
  font-size: 16px;
}

.slider {
  position: relative;
  width: 56px;
  height: 28px;
  border-radius: 100px;
  background-color: #e6e6e6;
  transition: background-color 0.2s;
  cursor: pointer;
}

.slider:hover {
  background-color: #c7c7c7;
}

.slider.left {
  background-color: #c7c7c7;
}

.slider.right {
  background-color: #3f51b5;
}

.slider-knob {
  position: absolute;
  top: 2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s;
  transform: translateX(${props => props.isDescending ? '28px' : '0'});
  user-select: none;
}

.slider-knob span {
  font-size: 12px;
}

.slider.left .slider-knob {
  transform: translateX(0);
}

.slider.right .slider-knob {
  transform: translateX(28px);
}

.slider-knob:active {
  transform: scale(0.8);
}
