/* Base styling */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

/* Header and nav styling */
.header {
  width: 100%;
  z-index: 1000;
  padding-bottom: 50px;
}

.nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #393e46;
  padding: 1rem;
}

/* Add responsive media query */
@media (max-width: 768px) {
  .nav {
    flex-direction: column;
  }
}

.authenticated_as {
  display: inline-block;
  margin-right: 10px;
  color: #eeeeee;
  font-weight: bold;
  vertical-align: middle;
}

/* Add responsive media query */
@media (max-width: 768px) {
  .authenticated_as {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

.nav_list {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav_item {
  color: #ffffff;
  margin: 0 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  text-decoration: none;
  position: relative;
}

/* Add responsive media query */
@media (max-width: 768px) {
  .nav_item {
    margin: 0.5rem 0;
  }
}

.nav_item:hover {
  color: #d4d4d4;
}

.nav_item::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 50%;
  background-color: #00b7fd;
  transition: all 0.3s ease;
}

.nav_item:hover::before {
  width: 100%;
  left: 0;
}

.logout_btn {
  color: #0092ca;
  position: relative;
  cursor: pointer;
  text-decoration: none;
}

.logout_btn:hover {
  color: #00b7fd;
  text-shadow: 0 0 10px #00b7fd;
}

.law_svg {
  width: 50px;
  height: 50px;
  margin: 0 1.5rem;
}

/* Add responsive media query */
@media (max-width: 768px) {
  .law_svg {
    margin: 0 0 1rem 0;
  }
}

.closed {
  display: none;
}

.open {
  display: flex;
  flex-direction: column;
}

/* Add responsive media query */
@media (max-width: 768px) {
  .nav_list {
    display: none;
  }

  .nav_list.open {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #393e46;
    border: 1px solid #d4d4d4;
    border-radius: 5px;
    padding: 1rem;
    z-index: 1000;
  }
}

.breadcrumb_button {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 768px) {
  .breadcrumb_button {
    display: inline-block;
  }
}
