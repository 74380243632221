@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7.5px);
  }
}

@keyframes scale {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

svg {
  display: block;
  margin: 0 auto;
}

#download-icon {
  animation: bounce 2s infinite;
}

#excel-icon {
  animation: scale 3s infinite;
}
