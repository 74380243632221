.clear-btn-cust {
    position: relative;
    overflow: hidden;
    padding: 10px 25px;
    border: none;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    background-color: #2196f3;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .clear-btn-cust:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    transform: translate(-50%, -50%);
    transition: all 0.5s;
  }
  
  .clear-btn-cust:hover:before {
    width: 200%;
    height: 200%;
  }
  
  .clear-btn-cust:focus {
    outline: none;
  }
  
  .clear-btn-cust:active {
    transform: scale(0.9);
    transition: all 0.2s;
  }
  