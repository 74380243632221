.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
    z-index: 9999;
  }
  
  .loading {
      display: flex;
      justify-content: space-between;
      width: 70px;
      height: 18px;
      margin-bottom: 20px;
  }
  
  .loading-dot {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #161616;
      animation: loading-dot 1s ease-in-out infinite;
  }
  
  @keyframes loading-dot {
      0% {
          transform: scale(0);
      }
  
      50% {
          transform: scale(1);
      }
  
      100% {
          transform: scale(0);
      }
  }
  
  .loading-text {
      font-size: 1.2rem;
      font-weight: 500;
      color: #161616;
  }