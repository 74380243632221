.card:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.24);
  transform: translateY(-2px);
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.expand-btn {
  background-color: #93a9d2;
  color: #fff;
  border-radius: 10px;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
}

.info-wrapper {
  margin-top: 1rem;
}

.info-text {
  font-size: 1rem;
  margin: 0;
  padding-left: 1.5rem;
  position: relative;
}

.info-text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.2rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #93a9d2;
  border-radius: 50%;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.modal-content .info-wrapper {
  margin-bottom: 1rem;
}

.modal-content .info-wrapper h3 {
  margin-bottom: 0.5rem;
}

.modal-content .info-text {
  margin-bottom: 0.25rem;
}

.close-btn {
  background-color: #93a9d2;
  color: #fff;
  border-radius: 10px;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 1rem;
}

.card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 16px;
}

.card {
  flex: 0 0 calc(50% - 1rem);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  transition: all 0.3s ease-in-out;
  padding: 1rem;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 5px;
}

@media screen and (min-width: 768px) {
  .card {
    flex: 0 0 calc(25% - 2rem);
  }
}

