@import url('https://weloveiconfonts.com/api/?family=entypo|fontawesome');
@import url('https://fonts.googleapis.com/css?family=Roboto');

.box {
  width: 17em;
  height: auto;
  margin: 1em auto;
  background: #ffffff;
  border-radius: 0.7em;
  overflow: hidden;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
}

.selectContainer {
  display: block;
}

.select {
  line-height: 275%;
  border-bottom: 0.15em solid rgba(50, 115, 220, 0.3);
  padding: 0 15%;
  color: #3273dc;
  position: relative;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  transition: background 0.3s ease, color 0.3s ease;
}

.select:last-child {
  border-bottom: none;
}

.select::before {
  font-family: 'FontAwesome', sans-serif;
  position: absolute;
  margin: 0 -1.5em;
  left: 0.5em;
  color: #3273dc;
  transition: color 0.3s ease;
}

.selectContainer:hover .select {
  background: #3273dc;
  color: white;
}

.selectContainer:hover .select::before {
  color: white;
}

.fa-home::before {
  content: '\f015';
}

.fa-comments::before {
  content: '\f086';
}

.fa-pencil::before {
  content: '\f040';
}

.fa-inbox::before {
  content: '\f01c';
}
