:root {
  --light-gray: #eeeeee;
  --primary-blue: #0092ca;
  --dark-gray: #393e46;
  --white: #ffffff;
  --medium-gray: #d4d4d4;
  --accent-blue: #00b7fd;
}

.customCnpj_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  color: var(--dark-gray);
}

.customCnpj_title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.customCnpj_input_group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.customCnpj_input {
  flex: 1;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid var(--medium-gray);
  outline: none;
  margin-right: 1rem;
}

.customCnpj_button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: var(--primary-blue);
  color: var(--white);
  cursor: pointer;
  transition: background-color 0.3s;
}

.customCnpj_button:hover {
  background-color: var(--accent-blue);
}

.customCnpj_results {
  margin-top: 2rem;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  background-color: var(--white);
}

.customCnpj_result_row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.customCnpj_result_row:not(:last-child) {
  border-bottom: 1px solid var(--light-gray);
}

.customCnpj_label {
  font-weight: 600;
  flex: 1;
  margin-right: 1rem;
}

.customCnpj_value {
  text-align: right;
  font-family: monospace;
  flex: 1;
  text-align: left;
}
