.block-counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #123a5d;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
}

.block-counter:hover {
    transform: scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.block-counter__count {
    margin-right: 10px;
}

.block-counter__label {
    text-transform: uppercase;
    letter-spacing: 1px;
}