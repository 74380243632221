.lawProcessCard-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }
  
  .lawProcessCard-col {
    flex: 1 0 25%;
    max-width: 25%;
    padding: 0 8px;
    animation: fadeInUp 0.5s ease-in-out forwards;
  }
  
  .lawProcessCard-card {
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
    margin-bottom: 16px;
    overflow: hidden;
    transform: translateY(20px);
    transition: all 0.2s ease-in-out;
  }
  
  .lawProcessCard-card:hover {
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(16px);
  }
  
  .lawProcessCard-content {
    padding: 16px;
    text-align: left;
  }
  
  .lawProcessCard-titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  
  .lawProcessCard-title {
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .lawProcessCard-expandBtn {
    background-color: #2196f3;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 6px 12px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .lawProcessCard-expandBtn:hover {
    background-color: #1e88e5;
  }
  
  .lawProcessCard-infoWrapper {
    margin-bottom: 16px;
  }
  
  .lawProcessCard-infoText {
    font-size: 1rem;
    margin: 0;
    padding: 2px 0;
  }
  
  @media (max-width: 992px) {
    .lawProcessCard-row {
      margin: 0 -4px;
    }
  
    .lawProcessCard-col {
      padding: 0 4px;
    }
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }