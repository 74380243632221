.card {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
    transition: all 0.3s ease-in-out;
  }
  
  .card:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.24);
    transform: translateY(-2px);
  }
  
  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .expand-btn {
    background-color: #93a9d2;
    color: #fff;
    border-radius: 10px;
  }
  
  .info-wrapper {
    margin-top: 1rem;
  }
  
  .info-text {
    font-size: 1rem;
    margin: 0;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .info-text:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.2rem;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #93a9d2;
    border-radius: 50%;
  }
  