@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.LoginForm {
  font-family: "Roboto", sans-serif;
}

.LoginForm__body {
  background-color: #393e46;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  position: relative;
}

.LoginForm__container {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  position: relative;
  z-index: 2;
}

.LoginForm__container h4 {
  color: #393e46;
  font-weight: 300;
}

.LoginForm__container .input-field input {
  color: #393e46;
  border-bottom: 1px solid #d4d4d4 !important;
  box-shadow: none !important;
}

.LoginForm__container .input-field input:focus {
  border-bottom: 1px solid #00b7fd !important;
  box-shadow: 0 1px 0 0 #00b7fd !important;
}

.LoginForm__container .input-field label {
  color: #d4d4d4;
}

.LoginForm__container .input-field label.active {
  color: #0092ca;
}

.LoginForm__btn {
  background: linear-gradient(45deg, #0092ca, #00b7fd);
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.2s ease;
  padding: 0.5rem 2rem;
  color: #ffffff;
  outline: none;
}

.LoginForm h4 {
  color: #393e46;
  font-weight: 300;
}

.LoginForm__btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.LoginForm__btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

.LoginForm__btnFlat {
  color: #0092ca;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.LoginForm__animatedBg {
  background: linear-gradient(135deg, #0092ca, #00b7fd);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: skewX(-10deg) translateX(-150%);
  animation: LoginForm__bg-animation 10s linear infinite;
  z-index: 1;
  opacity: 0.15;
}

@keyframes LoginForm__bg-animation {
  0% {
    transform: skewX(-10deg) translateX(-150%);
  }

  100% {
    transform: skewX(-10deg) translateX(150%);
  }
}

@media (max-width: 400px) {
  .LoginForm__container {
    border-radius: 0;
  }
}