.lawProcessModal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lawProcessModal {
  background: #f5f5f5;
  color: #333;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  max-width: 500px;
  min-width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lawProcessModal-title {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.lawProcessModal-lawyersWrapper {
  margin-bottom: 1.5rem;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.lawProcessModal-detailsTitle {
  font-size: 1.2rem;
  font-weight: bold;
}

.lawProcessModal-details {
  font-size: 1.2rem;
  white-space: pre-wrap;
}

.lawProcessModal-infoText {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
  animation-delay: 0.3s;
}

.lawProcessModal-infoPair {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.lawProcessModal-infoKey {
  text-align: left;
  font-weight: bold;
}

.lawProcessModal-infoValue {
  text-align: right;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.lawProcessModal-lawyersWrapper {
  margin-bottom: 1rem;
}

.lawProcessModal-lawyersTitle {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.lawProcessModal-lawyer {
  margin-bottom: 1rem;
}

.lawProcessModal-lawyerTitle {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #555;
}

.lawProcessModal-lawyerItems {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 0.5rem;
}

.lawProcessModal-lawyerItem {
  font-size: 0.9rem;
  line-height: 1.4;
  color: #777;
}

.lawProcessModal-closeBtn {
  align-self: flex-end;
  background-color: #93a9d2;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.lawProcessModal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lawProcessModal-lawyerList {
  display: flex;
  flex-direction: column;
}

.lawProcessModal-lawyerList:not(:last-child) {
  margin-bottom: 1rem;
}

.medal {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 50%;
}

.medalStyleForJ {
  background-color: gold;
  color: black;
}

.medalStyleForF {
  background-color: silver;
  color: black;
}