.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .pagination-button {
    background-color: #007bff;
    border-color: #007bff;
    color: white;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .pagination-button:disabled {
    background-color: #6c757d;
    border-color: #6c757d;
    cursor: not-allowed;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #0056b3;
    border-color: #0056b3;
  }
  
  .pagination-info {
    margin: 0 10px;
  }
  
  .pagination-input {
    width: 50px;
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }
  
  .pagination-select-container {
    position: relative;
    display: inline-block;
  }
  
  .pagination-select {
    position: relative;
    z-index: 1;
  }

  .pagination-select-label {
    margin-right: 8px;
  }
  
  .custom-select {
    position: relative;
    display: inline-block;
  }
  
  .custom-select select {
    display: none;
  }
  
  .select-selected {
    background-color: #4CAF50;
  }
  
  /* Style the arrow inside the select element: */
  .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  }
  
  /* When the select box is clicked, show the options: */
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  }
  
  /* Style the items (options), including the selected item: */
  .select-items div,.select-selected {
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,.2);
    cursor: pointer;
    background-color: #4CAF50;
    text-align: left;
  }
  
  /* Style items (options): */
  .select-items {
    position: absolute;
    background-color: #4CAF50;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .pagination-select-label {
    display: inline-block;
    margin-right: 10px;
  }
  
  /* The container must be positioned relative: */
  .pagination-select-container {
    position: relative;
    display: inline-block;
    margin-left: 10px;
  }
  
  /* Hide the original select box: */
  .pagination-select {
    display: none;
  }
  
  /* Style the custom select box: */
  .custom-select {
    position: relative;
    display: inline-block;
  }
  
  .custom-select select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    padding: 10px 20px;
    outline: none;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
  }
  
  .custom-select select:hover {
    background-color: #e8e8e8;
  }
  
  /* Style the arrow inside the select box: */
  .select-selected:after {
    position: absolute;
    content: "";
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #333 transparent transparent transparent;
  }
  
  /* Style the arrow when the select box is open: */
  .select-selected.select-arrow-active:after {
    border-color: transparent transparent #333 transparent;
    top: 43%;
  }
  
  /* Style the items (options), including the "selected" item: */
  .select-items div,.select-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.2s ease-in-out;
  }
  
  .select-items div:hover, .same-as-selected {
    background-color: #e8e8e8;
  }